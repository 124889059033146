.backdrop {
  position: absolute;

  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  
  background-image: url('/assets/img/bg.webp');
  background-size: cover;

  .jumbotron {
    background: none;
    text-align: center;
  
    .container {
      background-color: rgba($color: #212121, $alpha: 0.6);
      color: white;
      
      left: 0;
      right: 0;
      
      h1 {
        border-bottom: 1px solid black;
        display: inline-block;
        font-weight: 400;
        font-size: 85px
      }

      .nav .nav-item {
        font-size: 2rem;
      }
    }
  }
}

.main {
  position: absolute;

  width: 100%;
  top: 100%;
  left: 0;
  
  background-color: rgb(51, 51, 51);

  > .section {
    background-color: #5C6BC0;

    &:nth-child(2n + 1) {
      background-color: #4FC3F7;
    }
  }
}

.about {
}

.external {
  img {
    max-width: 250px;

    border-radius: 50%;
    border: 10px solid rgba($color: #212121, $alpha: 0.4);

    &:hover {
      transform: scale(1.05);
      border: 10px solid rgba($color: #212121, $alpha: 0.5);
    }
  }
  
  .link {
    &:link, &:visited, &:active {
      color: rgba($color: #212121, $alpha: 0.8);
      text-decoration: none;
    }
  
    &:hover {
      color: #212121; // material grey 900
      text-decoration: none;
    }
  }
}
