html,
body {
  font-family: 'Open Sans', sans-serif;
}

a {
  color: inherit;
  text-decoration: underline;
}

blockquote {
  background-color: rgba(0, 0, 0, 0.2);
  border-left: 5px solid rgba(0, 0, 0, 0.4);

  padding: 0.5em;
}

.section {
  width: 100%;
  padding: 20px;

  .section-header {
    text-align: center;
  }

  h1 {
    font-size: 50px;
  }
  
  h3 {
    font-size: 25px;
  }

  p {
    font-size: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.light {
  color: white;
}
  
.dark {
  color: lightgrey;
}
